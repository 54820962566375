<template>
  <div class="tos">
    <Detail :deteil_title="'Privacy Policy'"></Detail>
    <div class="detailwrap detail_text">
      <P>The use of Yiha through our mobile applications may involve collection and use of your information. This Privacy
        Policy explains and informs you how your information is collected, used and disclosed by Yiha with respect to your
        access and use of our services through our mobile applications. It is important for you to understand this Privacy
        Policy and how you may protect your information, so please read this Privacy Policy carefully.</P>
      <P>Application Scope of this Privacy Policy</P>
      <P>By using Yiha, you agree that we may access, collect, use and share your information in accordance with this
        Privacy Policy, as revised from time to time. In addition, your use of Yiha signifies that you agree with the
        terms of this Privacy Policy. If you do not agree to the terms of this Privacy Policy, do not use Yiha.</P>
      <P>This Privacy Policy is incorporated into and forms part of the Yiha Terms of Service that you have agreed to in
        order to use Yiha. Any terms used in this Privacy Policy will have the same meaning as the equivalent defined
        terms in the Yiha Terms of Service unless otherwise defined in this Privacy Policy or the context requires
        otherwise. Unless otherwise specified in this Privacy Policy, these terms in the Yiha Terms of Service shall also
        be applicable in this Privacy Policy.</P>
      <P>Any information that is collected is subject to the Privacy Policy in effect at the time such information is
        collected. We may, however, modify and revise our Privacy Policy from time to time. If we make any change to this
        policy, which we deem to be material changes, we will (where reasonably practicable) notify you of such changes by
        posting them on our website, through our services or by sending you an email or other notification, and we will
        indicate when such changes will become effective. By continuing to access or use our services after the changes
        become effective, with or without notification, you are agreeing to be bound by the revised policy.</P>
      <P>Yiha may link you to social media or other features or services (including websites, plug-ins and widgets)
        provided by a third party. Please note that this Privacy Policy doesn’t apply to the information collected by any
        third-party websites, services or applications that can be accessed through our services, and does not apply to
        the information collected by other companies and organizations who advertise their services on Yiha. We bear no
        liability for any third party use of any information provided by you to them.</P>
      <P>1.Information You Provide and We Collect</P>
      <P>2.1 To provide the services of Yiha, we access, collect, store and use the following Information relating to you:
      </P>
      <P>1) any information included in your Yiha profile which are visible to other users of Yiha, or information you
        make available to us via your use of Yiha, which may include your photos, gender, and city or area;</P>
      <P>2) information about your and others’ usage of and interaction with the service and our application, including
        usage by geographies, device and connection information, IP address, device capability, bandwidth, statistics on
        page views, and network type;</P>
      <P>3) information we collect as you use Yiha, which may include:</P>
      <P>✓ technical information, such as your mobile carrier-related information, configuration information made
        available by other programs you use to access Yiha, your IP address, your location/country/region/time zone, your
        device's version and identification number, user settings, and the operating system;</P>
      <P>✓ information about the likes and dislikes you have made using Yiha.</P>
      <P>2.2 Special Note about Sensitive Personal Information</P>
      <P>To provide certain features (e.g. online video calling), we must access your microphone, camera, or location,
        with your permission, as described below:</P>
      <P>1) Microphone: We access the microphone on your device (with your permission) to record audio messages and
        deliver sound during video calls.</P>
      <P>2) Camera: We access the camera on your device (with your permission) to take your profile pictures and deliver
        realtime images during video calls.</P>
      <P>3) Location: We display your current location on your profile page (with your permission) to provide personalized
        and optimized experience for you and other users.</P>
      <P>By using Yiha, you agree that all processing activities of sensitive personal information are for the purposes
        and in the manner as described in this Privacy Policy.</P>
      <P>Please note that we do not use your sensitive personal information for the purpose of making direct marketing
        unless agreed by you.</P>
      <P>2.3 Special Note about Children’s Information</P>
      <P>The services of Yiha are not for anyone under the age of 【18】, and anyone under the specified minimum age may not
        create a Yiha account or use Yiha services. Hence, we do not knowingly collect personal information from any data
        subject under such age. If we learn that we have collected personal information of a data subject under 【18】, we
        will take steps to delete such information from our files as soon as possible.</P>
      <P>1.How We Use the Information We Collect</P>
      <P>3.1 Purposes</P>
      <P>We may use your Information for any of the following purposes:</P>
      <P>1) to provide services to you (including both the services currently provided by us via Yiha and the services to
        be rendered by us via Yiha in the future);</P>
      <P>2) to personalize and improve our services and your experiences when you use our services by, among other things,
        suggesting friends or profile information, or customizing the content we show you;</P>
      <P>3) to respond to inquiries, for the purposes of customer service, security, archival and backup purposes in
        connection with providing services of Yiha;</P>
      <P>4) to monitor and analyze usage and trends of our services;</P>
      <P>5) to enhance the safety and security of our products and services;</P>
      <P>6) to verify your identity and prevent fraud or other unauthorized or illegal activity;</P>
      <P>7) to enforce our Terms of Use and other usage policies.</P>
      <P>Yiha allows you to share text messages, photos, screenshots, videos and other communications in the Yiha
        application with other users, and if you choose to do so your text messages, photos, screenshots, videos and other
        communications will be stored on our servers. In addition, if you choose to share these items with other Yiha
        users, we may not be able to remove them from our servers or make them unavailable to anyone you have shared them
        with. Sending and sharing text messages, photos, screenshots, videos or other communications in the Yiha
        application is your decision. By choosing to share that information, you should understand that you may no longer
        be able to control how that information is used and that it may become publicly available (depending in part on
        your actions or the actions of others with whom you have shared the information). Yiha is not responsible for any
        use or misuse of information you share.</P>
      <P>3.2 Transfer and Storage</P>
      <P>We operate and may continue to operate servers in a number of jurisdictions around the world, so the server on
        which your personal information is used and stored may not be in your jurisdiction.</P>
      <P>You consent to the transfer of your personal information (both inside and outside of your jurisdiction) for the
        purposes described in this Privacy Policy.</P>
      <P>3.3 Sharing</P>
    <P>Your information is shared with others in the following ways:</P>
    <P>Sharing on our products</P>
    <P>1) Public information in your profile can be seen by anyone. Please do not post or add personal data to your
      profile that you would not want to be publicly available.</P>
    <P>2) Your active status or usage information is collected and displayed to other users (e.g. whether you are
      currently active on Yiha).</P>
    <P>Sharing with third-party partners</P>
    <P>By using Yiha, you agree to the third party use, sharing, and transfer of your personal information (both inside
      and outside of your jurisdiction) as prescribed in this Privacy Policy.</P>
    <P>1) Measurement partners.</P>
    <P>We may share information about you in anonymous and/or aggregated form with third parties for usage analytics (to
      help us better understand who is using our services and how), and for industry analysis, research, and other
      similar purposes.</P>
    <P>2) Partners offering services in Yiha</P>
    <P>Your information may be accessed and used by our service providers who are working with us in connection with the
      operation of our services. These service providers may have access to your information but only to the extent
      necessary to perform services on our behalf and are obligated not to disclose that information or use it for any
      other purposes. These providers will collect the information sent by your mobile phone, tablet or desktop
      computer, and their use of that information is governed by their applicable privacy policy.</P>
    <P>3) Law enforcement</P>
    <P>We may share information about you if we are (or if we believe we are) required by law or legal process (such as
      a subpoena, warrant or court order), if we have to respond to a lawful request from legal authorities to disclose
      such information, or if we need to enforce or apply this Privacy Policy, our Terms of Service or our other
      policies.</P>
    <P>4) New owner</P>
    <P>If we are acquired by a third party or may re-organise our group structure, your information may be transferred
      within our group and/or to a third party who will continue to operate Yiha or similar services under either this
      Privacy Policy or a different privacy policy which will be notified to you. The party to which your personal
      information is transferred may be located, and use your Information, outside of your jurisdiction.</P>
    <P>Other than as permitted under this Privacy Policy or as otherwise consented to by you or as required by law or
      legal process, we will not transfer your personal information to any other third parties for their processing for
      any purposes.</P>
    <P>3.4 Security Measures We Take to Protect Your Information</P>
    <P>We employ administrative, physical and electronic measures designed to protect your information from unauthorized
      access, however, despite these efforts, no security measures are perfect or impenetrable and no method of data
      transmission can be guaranteed against any interception or other type of misuse. In the event that your personal
      information is compromised as a result of a breach of security, we will promptly notify you if your personal
      information has been compromised, as required by applicable law.</P>
    <P>Where we do share your personal information with any third parties, we will use reasonable efforts to ensure that
      such third parties only use your personal information: (i) in compliance with this Privacy Policy; and (ii)
      subject to any other instructions we give them, including any appropriate confidentiality and security measures
      that we implement.</P>
    <P>1.How to Access Your Information</P>
    <P>You can access and modify the information you provided to us through your account.</P>
    <P>If you believe your information posted on Yiha is not authentic or accurate and can provide us with reasonable
      proof regarding this, you can require us via the contact information provided in this Privacy Policy to correct
      these unauthentic or inaccurate information and we will use reasonable efforts to honour your request; however, we
      may retain an archived copy of your information as required by law and/or for record keeping purposes.</P>
    <P>Once your account is closed or deleted, you will no longer have access to your message history, preferences or
      any other information associated with your use of our services. Even after you remove information from your
      account or profile, copies of that information may remain viewable elsewhere, to the extent it has been shared
      with others, it was otherwise distributed pursuant to your privacy settings, or it was copied or stored by other
      users. Additionally, we will retain your personal data even after you have closed your account if reasonably
      necessary to comply with our legal obligations (including law enforcement requests), meet regulatory requirements,
      resolve disputes, maintain security, prevent fraud and abuse, or enforce the Terms of Service. We will retain
      de-personalized information after your account has been closed. In some cases, we may not be able to update, amend
      or remove your personal information, in which case we will let you know why we are unable to do so, for example,
      certain requests may require disproportionate technical effort or extremely impractical, or are prohibited by law.
    </P>
    <P>Since Yiha will be used in multiple jurisdictions and we may not be possible to identify all the inconsistency
      between certain terms of this Privacy Policy and the applicable law in your jurisdiction, thus you agree to notify
      us once such inconsistency is identified by you. Under this circumstance we will take necessary methods to
      eliminate such inconsistency, including deleting these personal information improperly collected or used by us.
    </P>
    <P>1.Contacting Us</P>
    <P>If you have any questions about our Privacy Policy or any privacy-related matters, please contact us via email at
      【contact.yakochat.com】.</P>

  </div>
  <div class="detailunderline"></div>
  <!-- <Footer></Footer> -->
</div></template>


<script>
import Detail from '@/components/Detail/Detail.vue'
import Footer from '@/components/Footer/Footer.vue'
export default {
  components: {
    Detail,
    Footer
  }

}
</script>

<style></style>