<template>
  <div class="footer">
    <div class="wrap">
      <div class="footernav">
        <div class="f_logo">
          <img src="../img/logo2.png" alt="" />
          <!-- <p class="address">广州乐维智能科技有限公司<br/>广东省广州市越秀区环市东路339号2001自编房号2016-20室自编A240</p> -->
        </div>
        <div class="r">
          <Nav class="rnav" :hrefList="hrefList"></Nav>
        </div>
      </div>
      <div class="footerinfo">
        <!-- <p>© 2023 杭州硅基人工智能科技有限公司.</p> -->
        <p>© {{CurrentYeah}} All Rights Reserved.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from '@/components/Nav/Nav.vue';
export default {
  data() {
    return {
      CurrentYeah: 2024,
      hrefList: [], //['About Us', 'Products', 'Contact Us']
    };
  },
  components: {
    Nav,
  },
  mounted() {
    const CurrentYeah = new Date().getFullYear();
    this.CurrentYeah = CurrentYeah;
  },
};
</script>

<style lang="scss" scoped src="./footer.scss">
</style>