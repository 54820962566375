import { createStore } from 'vuex'
import { ElMessage, ElLoading } from 'element-plus';
import Global from '@/assets/js/Global';
export default createStore({
  state: {
    navshow:false,
    DialogDelTip: false,
    DialogDelInfo: false,
  },
  getters: {
    
  },
  mutations: {
    setDialogState(state, data) {
      state[data.setstate] = data.stateinfo
      console.log(data,state[data.setstate]);
    },
    changenavshow(state,show){
      console.log('commmm');
      state.navshow = show
      console.log(state.navshow );
    }
  },
  actions: {
    async deleteAccountsHandle(context, data) {
      context.commit('setDialogState', { setstate: 'DialogDelInfo', stateinfo: false })
      const loadingInstance = ElLoading.service({ background: 'rgba(0,0,0,0.7)' })
      const ar_response = await Global.deleteAccountWeb('ef96349e-dcd0-4e2a-8361-62c2b881047b', data.userid, data.password);
      const in_response = await Global.deleteAccountWeb('4870cef8-2135-46ab-9ef7-34ac1e6e8391', data.userid, data.password);
      if (ar_response?.code === 200 || in_response?.code === 200) {
        loadingInstance.close();
        ElMessage({
          message: `ID:${data.userid}, Delete success`,
          type: 'success' // success、warning、error
        });
        return
      }
      loadingInstance.close();
      ElMessage({
        message: 'account or password invalid, plz check and try again later',
        type: 'warning' // success、warning、error
      });
      return
    }
  },
  modules: {

  }
})
