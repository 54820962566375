<template>
  <div class="detail">
    <div class="detail_headbg">
      <div class="content">
        <!-- <div class="detail_icon">
          <img :src="detail_icon" />
        </div> -->
        <p class="detail_title">{{ deteil_title }}</p>
      </div>

    </div>

  </div>
</template>

<script>
import Nav from '@/components/Nav/Nav.vue'
// import downLoadAPK from '@/utils/index'
export default {
  props: ['deteil_title', 'detail_icon'],
  components: {
    Nav
  },
  data() {
    return {
      hrefList: ['About Us', 'Contact Us', 'TERMS AND CONDITIONS', 'Privacy Policy']
    }
  },
  methods: {
    // downLoad() {
    //   downLoadAPK()
    // },
    cslide() {
      let sh = document.documentElement.scrollTop
      if (sh >= 50) {
        document.getElementsByClassName('header_nav')[0].classList.add('navbg')
      }
      if (sh < 50) {
        document.getElementsByClassName('header_nav')[0].classList.remove('navbg')
      }
    }
  },


}
</script>

<style lang="scss" scoped src="./detail.scss"></style>