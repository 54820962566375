<template>
  <div class="nav">
    <ul>
      <li class="item" v-for="(item, index) in hrefList" :key="index" @click="intoLocal(index)">
        {{ item }}
      </li>
      <el-dropdown class="item" style="border:none">
        <span>
          My Account
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="ShowTip">Delete Account</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  props: ['hrefList'],
  data() {
    return {

    }
  },
  methods: {
    ...mapMutations(['setDialogState']),
    ShowTip() {
      this.setDialogState({ setstate: 'DialogDelTip', stateinfo: true })
    },

    intoLocal(index) {
      console.log(index);
      if (index == 0) {
        this.$router.push({
          path: '/',
          query: {
            about: 1
          }
        })
        return
      }
      if (index == 1) {
        this.$router.push({
          path: '/',
          query: {
            contactus: 1
          }
        })
        return
      }
      if (index == 2) {
        document.documentElement.scrollTop = 0
        this.$router.push('/service')
        return
      }
      if (index == 3) {
        document.documentElement.scrollTop = 0
        this.$router.push('/privacypolicy')
        return
      }
    }
  }
}
</script>

<style lang="scss" scoped src="./nav.scss"></style>